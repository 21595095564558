
/*
    primary (vedi App.js palette)
    inthem/opus giallo  E1E002 primary.main
                        DBDB00 link in Home/Header
                        AAAA00
                        777700 secondary
                        ------
    azzurro             61DAFB primary.main
                        55CCEE link in Home/Header
                        2299BB
                        006688
                        ------
    sfondo Home/Header  282c34 

    goodValue #0a0
    error red
    dafare #993300
*/

html,
body,
.App,
#root{
  height:100%;
  margin: 0
}
body {
    font-family: Calibri, Segoe, Helvetica, sans-serif !important ;
    font-size: 16px !important;
}
.app-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 100%;
  min-height: 0;
}
div.Header,
.Header {
    height: 64px;
}
.Header,
.Footer {
  flex: 0 0 0;
}
.Header .MuiToolbar-root {
    min-height: 64px;
}
.content-container{
  flex: 1 0 0;
  min-height: 0;
  overflow: auto;
  padding-top: 10px;
}
.contentWithToolbar {
    margin-top: 50px;
}
.contentWithTabAndToolbar {
    margin-top: 98px;
}
.formToolbarInTab,
.formToolbar {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: 50px;
    border-bottom: 1px #999 solid;
    text-align: center;
}
.formToolbarInTab {
    top: 112px;
}
.formToolbarInTab button,
.formToolbar button {
    margin-top: 6px !important;
    margin-right: 4px;
    margin-bottom: 16px;
}
.Header h1 {
    font-size: 20px;
}
.MuiAppBar-positionAbsolute {
    top: 64px !important;
}

.MuiButton-outlinedSecondary {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

table, td, th {
    border-collapse: collapse;
}
.margin20 {
    margin-right: 20px;
    margin-left: 20px;
}
.margin20all {
    margin: 20px;
}

:any-link {
  color: #AAAA00;
}

.App {
  color: #AAAA00;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  */
  font-size: calc(10px + 2vmin);
  color: white;
}
.Home-header :any-link {
  color: #DBDB00;
}

.goodValue {
    color: #0a0;
    font-weight: bold;
}
.red,
.error,
.badValue {
    color: red;
    /* font-weight: bold; */
}
.gold {
  color: #AAAA00 !important;
}
#languageDiv {
    width: 100%;
}
#languageDiv button {
    float: right;
    margin: 10px 20px 10px 0;
    border: 0;
    padding: 0;
    height: 24px;
    cursor: -webkit-grab; 
    cursor: grab;
}
.hidden {
    display: none;
}
.visible {
    display: block;
}
#HomeDiv {
    font-family: "Maven Pro", "maven pro";
    min-width: 280px;
}
#HomeDiv #title {
    padding: 0 10px;
    text-align: center;
}
.UnderFooter {
    height: 44px;
}
.xxxxApp-logo {
    max-width: 348px;
}
@media (max-width:348px) {
    .xxxxApp-logo {
        max-width: 280px;
    }
}
.margintop30 {
    margin-top: 30px;
}
.backbutton {
    padding-top: 20px;
}
.asinistra {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}
.tuttoasinistra {
    text-align: left;
    width: 100%;
}

.Header.MuiPaper-root,
.Header :any-link,
.Header .MuiIconButton-root {
  background-color: #282c34 !important;
  color: #DBDB00 !important;
}
.blackColor,
.blackColor :any-link {
    color: black;
}
.top-margin-10 {
    margin-top: 10px;
}
.margin-sg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.vertMiddle {
    vertical-align: middle;
}
/* per riuscire a vedere l'elenco delle scelte di una select
   dentro dialog
   necessario forzare overflow-y a visible in
   MuiPaper-root e in MuiDialogContent-root
 */
#form-dialog .MuiPaper-root,
#form-dialog .MuiDialogContent-root {
    overflow-y: visible !important;
}
.vertalignTop {
    vertical-align: top;
}
.padding10 {
    padding: 0 10px;
}
.b_loginok {
    margin: 0 0 4px 0 !important;
}
.down {
    caption-side: bottom;
}
.width100,
.notetextarea {
    width: 100%;
}
.nodecoration {
    text-decoration: none;
}
.allCellLink {
    display: block;
}
.bcenter {
    margin: auto !important;
    display: block !important;
}
.margin2416 {
    margin: 24px 0px 16px !important;
}
.marginr10 {
    margin-right: 10px !important;
}
.marginr4 {
    margin-right: 4px !important;
}
.margint12 {
    margin-top: 12px !important;
}
/* valori della select non riescono a stare sopra una textbox 
   nella linea successiva: abbassare la textbox successiva,
   MA così non si riesce a fare input nella textbox!
   usare autocomplete
.zindex-1 {
}
*/
.rowreverse {
    flex-direction: row-reverse;
}
.tcenter,
.fileupload {
    text-align: center;
}
.tooltipDiv,
.tooltipDiv ul {
    padding-left: 8px;
}
.dafare {
    color: #993300;
}
.box {
    border-color: rgba(0,0,0,0.2) !important;
}
.boxgray {
    color: rgba(0,0,0,0.4) !important;
}
.divInBox {
    padding: 8px 10px;
}

.modulo li {
    margin-top: 60px;
}
.modulo li li,
.modulo li:first-child {
    margin-top: 0;
}
.important {
    color: #0099bb;
    font-weight: bold;
}
.big {
    font-size: 20px;
}
.MyConsentWelcome {
    font-size: 28px;
}
.MyConsentContainer {
    margin-top: 60px;
}
.MyConsentSpace {
    height: 60px;
}
.pointer {
    cursor: pointer;
}
.ulInGrid {
    margin: 0;
    padding-left: 14px;
}
.piccolo {
    font-size: 12px;
}

div[role=radiogroup] .MuiFormControlLabel-root {
    align-items: flex-start;
}
div[role=radiogroup] .MuiTypography-body1 {
    margin-top: 8px;
}

.pazienteDiv {
    position: absolute;
    top: 62px;
    left: 0;
    border: 1px black solid;
    width: 100%;
    height: 50px;
    line-height: 46px;      /* height - 4 */
    padding: 0px 4px;
    color: black;
    font-size: 12px;
    display: inline-flex;
    vertical-align: middle;
    background-color: white;
    color: black;
}
.pazienteDiv .MuiFormControl-marginDense {
    margin-top: 4px;
}
.pazienteDiv .closeDiv button {
    background-color: #e0e0e0;
}
.patientDetailDiv {
    margin-left: 8px;
}
.listPatientCode {
    font-size: 14px;
}
.listPatientInfo {
    font-size: 12px;
}
.font10 {
    font-size: 10px !important;
}
.nuovoPazienteDiv,
.closeDiv {
    padding: 0 4px;
}
.nuovoPazienteDiv {
    position: absolute;
    right: 10px;
}
.chiudiCartella {
    margin: 2px !important;
}





